import React from 'react';

class TextFullRow extends React.Component{
    render() {
        return(
            <div className={"row justify-content-center align-items-center pb-5"}>
                <h2 className={"mb-4 font-body-1"}>Search for another hospital or urgent care center in Optum Care Network–Monarch <a className={'font-body-1 font-navy-blue'} href={"https://www.monarchhealthcare.com/monarch-health-plan/partners/monarch-hospital-partners.html"}>here</a> </h2>

                <div className={"col-8 text-center"}>
                    <h3 className={"mb-4 font-body-1 font-blue"}><a href={"https://www.monarchhealthcare.com/services/urgent-care.html"}>Learn how to choose the right care for your situation.</a></h3>
                    <h3 className={"pb-2 pt-3"}>Optum Care Finder</h3>
                    <p className={"mb-4 px-5"}>
                        <h3 className={"font-body-1"}>
                            The Optum Care Finder can also help you find care on the go and is available for Apple® and Android™ devices.                        </h3>

                    </p>
                    <h4 className={"mb-4 font-body-1 font-blue"}><a href={"https://www.monarchhealthcare.com/locations-nav/carefinder-app.html"}>Learn more about the Optum Care Finder.</a></h4>

                </div>
            </div>

        )
    }
}

export default TextFullRow