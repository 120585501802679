import React from "react";
import FooterBottomAuxComponent from "./footer-bottom-aux.component";
import SOCIAL_DATA from "./social-data.data";

class FooterBottom extends React.Component {
    state = SOCIAL_DATA;

    render() {
        return (
            <div className="footer-component">
                <div className="container footer-aux-container pt-5">
                    <FooterBottomAuxComponent/>
                </div>
                <footer>
                    <div className="footer-top border-top pt-3 pb-2 d-none">
                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-xs-4 col-md-4 col-lg-4 text-centered mobile-link-center"><a
                                    href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> <span className={"font-small"}> Language Assistance /
                                   Non-Discrimination Notice</span>  </a></div>
                                <div className="col-xs-4 col-md-4 col-lg-5 text-centered mobile-link-center"><a
                                    href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> Asistencia de Idiomas /
                                    Aviso de no Discriminación </a></div>
                                <div className="col-xs-4 col-md-4 col-lg-3 text-centered mobile-link-center"><a
                                    href="https://www.optumcare.com/nondiscrimination-notice.html" target="_blank" rel="noreferrer"> 語言協助 / 不歧視通知</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid footer-fluid" style={{width: "90%"}}>
                        <div className="footer mt-5">
                            <div className="row flip-mobile flip-tablet mb-2">
                                <h3 style={{fontSize: '18px', color: '#595959'}}>Follow us</h3>
                            </div>
                            <div className="row flip-mobile flip-tablet">
                                <div className={"d-flex flex-start"}>
                                    {this.state.social.map( icon => {
                                        return(
                                            <a href={icon.linkUrl}>
                                                <img style={{width: "37px", marginRight:"5px"}} alt={icon.linkUrl} src={icon.image}/>
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid footer-fluid" style={{width: "90%"}}>
                        <div className="footer mt-4 pb-4">
                            <div className="row flip-mobile flip-tablet">
                                <div className="col-xs-12 col-md-12 col-lg-12 pl-0 text-left">
                                    <a rel="noreferrer"> <span className={"font-small"}>© 2022 Optum, Inc. All rights reserved</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/nondiscrimination.html" rel="noreferrer"><span className={"font-small"}>Language Assistance / Non-Discrimination Notice</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/privacy-policy.html" rel="noreferrer"><span className={"font-small"}>Privacy Policy</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/terms-of-use.html" rel="noreferrer"><span className={"font-small"}>Terms of Use</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/opt-out.html" rel="noreferrer"><span className={"font-small"}>Opt out</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/accessibility1.html?s=optum" rel="noreferrer"><span className={"font-small"}>Accessibility</span>  </a>
                                    <a target="_blank" href="https://www.optum.com/vulnerability.html" rel="noreferrer"><span className={"font-small"}>Vulnerability report</span> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default FooterBottom