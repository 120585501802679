import React from 'react';
import CARDS_DATA from "./cards-row.data";
import CardsCol from "../card-component/card-col.component";

class CardsRow extends React.Component {
    state = CARDS_DATA
    info = this.props.info
    render() {
        return (
            <div className={"row py-5 bg-beige justify-content-center"}>
                <div className={"row pb-5 mt-5"}>
                    <div className={"col-md-12 col-12 text-center"}>
                        {this.info.title && (
                            <h1>{this.info.title}</h1>
                        )}
                    </div>
                </div>
                {/*<div className={"row px-3 mb-5"}>*/}
                    <CardsCol data={this.info}/>
              {/*  </div>*/}
            </div>
        )
    }
}

export default CardsRow