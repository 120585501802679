import React from 'react';

const MapFrameComponent = () => {
    return(
      <>
          <iframe
          title="Google Maps location of a place"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJJ9yoXbkv3YARSRxBDJoIMAw&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E"
              width="100%" height="620" style={{border: 0}} allowFullScreen="" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"></iframe>
      </>
    );
}

export default MapFrameComponent