import React from "react";

class HeaderIntro extends React.Component{
    render(){
        return(
                <div className={"row pt-3 pb-0 mx-5"}>
                    <div className={"col-12 text-center p-5"}>
                        <h2 className={"font-body-1"}>
                            We know how important a good specialist is in helping to manage complex and chronic conditions.
                            That’s why we work with leading specialists in the area to make sure you’re getting the care you deserve.
                            When you need to see a specialist, you’ll be seen right here at the HCC.
                        </h2>
                    </div>
                </div>
            )

    }
}
export default HeaderIntro