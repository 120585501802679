import React from 'react';
import {Breadcrumb} from "react-bootstrap";
import './breadcrumb-top.styles.scss';

class BreadcrumbTop extends React.Component{
    info = this.props.info;
    render() {
        return(
            <div className={"row"}>
                <div className={"col-md-12 pt-4 pl-5 ml-5 pb-3 d-none d-md-flex"}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/"><span className={"font-body-3 font-navy-blue"}><b>Home</b></span></Breadcrumb.Item>
                        { this.info.items.map(item => {
                            return (
                                <Breadcrumb.Item href={item.linkUrl}>
                                    <span className={"font-body-3"}>{item.title}</span>
                                </Breadcrumb.Item>
                            )
                        })}
                    </Breadcrumb>
                </div>
            </div>
        )
    }
}

export default BreadcrumbTop