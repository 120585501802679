import React from 'react';
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import ButtonComponent from "../../button-component/button/button.component";

const CardComponent = ({title, text, image, linkText, linkUrl, noBackground, buttonText, inRowSize, isFooter, linkTarget}) => {
    return(
        <div className={inRowSize ? (`col-md-${inRowSize} col-12`) : 'col-12 col-md-12'}>
            <div className={"col-12 justify-content-center"}>
                <Card style={{borderRadius: "10px", maxWidth:"100%",}} className={noBackground ? "bg-transparent border-0" : ""}>
                    { image ? ( <Card.Img style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px", height:"175px"}} variant={"top"} alt={title} src={image}></Card.Img>) : null }
                    <Card.Body>
                        <Card.Title>
                            <h2 style={{fontSize:'20px'}} className={"font-navy-blue"}>{title}</h2>
                        </Card.Title>
                        <Card.Text>
                            <span className={isFooter ? ("font-body-3") : ("font-body-1")} dangerouslySetInnerHTML={{ __html: text }}></span>
                            {buttonText ? (<ButtonComponent title={buttonText} buttonLight={true}/>) : false }
                        </Card.Text>

                        { linkText ? (<Card.Text style={{background:"none"}}><h3 className={"font-body-2 font-blue"}><a href={linkUrl} target={'_blank'} rel={'noreferrer'}><b>{linkText}</b><FontAwesomeIcon className={"ml-3"} icon={faChevronRight}/></a></h3> </Card.Text>) : true  }
                    </Card.Body>
                </Card>
            </div>

        </div>
    )
}

export default CardComponent